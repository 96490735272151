import styled from '@emotion/styled'
import tw from 'twin.macro'

export const FormButton = styled.div`
  button {
    ${tw`
      w-full
      flex
      items-center
      justify-center
      px-5
      py-3
      border
      border-transparent
      uppercase
      text-sm
      sm:text-base
      leading-6
      font-medium
      rounded-full
      text-white
      bg-purple-700
      hover:bg-purple-600
      focus:outline-none
      focus:bg-purple-600
      transition
      duration-150
      ease-in-out
    `}
  }
`

export const FormInput = styled.div`
  label {
    ${tw`
      block
      text-sm
      font-medium
      text-gray-700
    `}
  }
  input {
    ${tw`
      appearance-none
      block
      w-full
      px-4
      py-3
      border
      border-white
      bg-white
      rounded-md
      shadow-lg
      text-gray-900
      placeholder-gray-500
      focus:outline-none
      focus:border-indigo-500
      text-sm
      sm:text-base
    `}
  }
`

export const FormInfo = styled.p`
  ${tw`
    mt-3
    text-xs
    leading-4
    text-gray-700
    text-center
  `}
  a {
    ${tw`
      text-indigo-600
      hover:text-indigo-500
      cursor-pointer
    `}
  }
`

export const FormSuccess = styled(FormInfo)`
  ${tw`
    text-green-500
  `}
  a {
    ${tw`
      text-green-800
    `}
  }
`

export const FormError = styled(FormInfo)`
  ${tw`
    text-red-500
  `}
  a {
    ${tw`
      text-red-800
    `}
  }
`
