import React from 'react'
import { Body, Button, Illustration, Section, Wrapper } from '../style'
import { FormError, FormInfo, FormInput, FormSuccess } from './style'

const Form = ({
  startOnboarding,
  setDeveloperEmail,
  buttonText,
  illustration,
  placeholder,
  formState,
  info,
}: any) => {
  return (
    <Section>
      <Wrapper>
        <Illustration>
          <img src={illustration.url} alt={illustration.alt} />
        </Illustration>

        <Body>
          <form onSubmit={(e) => startOnboarding(e)}>
            <FormInput>
              <div>
                <input
                  placeholder={placeholder}
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  onChange={(e) => setDeveloperEmail(e.target.value)}
                />
              </div>
            </FormInput>

            <Button type="submit">{buttonText}</Button>
          </form>
          {!formState.status ? (
            <FormInfo>
              {info[0]}
              <a href={info[1].url}>&nbsp;{info[1].text}</a>
            </FormInfo>
          ) : (
            <>
              {formState.status.ok && <FormSuccess>{formState.status.msg}</FormSuccess>}
              {!formState.status.ok && <FormError>{formState.status.msg}</FormError>}
            </>
          )}
        </Body>
      </Wrapper>
    </Section>
  )
}

export default Form
