import React, { FormEvent, useState } from 'react'
import axios from 'axios'
import { navigate } from 'gatsby'
import ValuePropositions from '../../components/forms/value_propositions'
import Form from '../../components/forms/form'
import { SignFormStyle } from '../../components/forms/style'

type FormStatus = {
  ok: boolean
  msg: string
}
type FormState = {
  submitting: boolean
  status?: FormStatus
}
const START_ONBOARDING_URL = `${
  process.env.REACT_APP_UMBRELLA_BASE_URL ||
  process.env.UMBRELLA_BASE_URL ||
  'https://auth.cryptr.eu'
}/api/cryptr/onboarding/start-submission`

const initialState = {
  submitting: false,
  status: undefined,
}

const DevelopersSignUpPage = ({ pageContext: { data, lang, templateId } }: any) => {
  const [developerEmail, setDeveloperEmail] = useState('')
  const [formState, setFormState] = useState<FormState>(initialState)

  const handleResponse = (ok: boolean, msg: string, form: EventTarget & HTMLFormElement) => {
    setFormState({
      submitting: false,
      status: { ok, msg },
    })
    if (ok) {
      form.reset()
      setFormState(initialState)
      navigate('/developers/confirmation-email?email=' + developerEmail)
    }
  }
  const startOnboarding = (event: FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget
    const params = { email: developerEmail }
    event.preventDefault()
    axios({
      method: 'post',
      url: START_ONBOARDING_URL,
      data: params,
    })
      .then((r) => {
        handleResponse(true, 'Email sent, please check your inbox', form)
      })
      .catch((r) => {
        console.error(r)
        handleResponse(false, (r.response && r.response.data) || 'an error occured', form)
      })
  }
  const formTitle = 'Sign up and experience Cryptr right now'
  const title = ['Get Beautiful', 'User Account Experience']
  const illustration = {
    url:
      'https://cryptruserui.cdn.prismic.io/cryptruserui/33a43eba-1dfa-4f87-8e06-8588f1809c08_cryptr_illustration_developers_sign_up.svg',
    alt: 'Developers sign up',
  }

  const label = 'Email address'
  const placeholder = 'ex: steve.wozniak@apple.1.com'
  const buttonText = 'START TO PROVIDE AUTH TO YOUR APP'
  const info = [
    'We care about the protection of your data. Read our',
    {
      text: 'Privacy Policy',
      path: '#',
    },
  ]
  const valuePropositions = [
    {
      strongText: 'No credit card',
      normalText: '(of course)',
    },
    {
      strongText: '< 15 min',
      normalText: 'Your authentication',
    },
    {
      strongText: 'No passwords',
      normalText: 'for your users',
    },
  ]
  return (
    <SignFormStyle>
      {/* 
        TODO: Refacto helmet to be able to use it outside the layout 
        and add the following parameters:  
          -> helmetTitle="Sign Up | Free community plan forever"
          -> helmetDescription="Secured & Frictionless Customer Login, quick and simple to set up. Codeless & editable self-hosted authentication."
          -> helmetImage={'/metatag_image_cryptr.png'}
          -> helmetUrl={`https://www.cryptr.co/developers/sign-up`}
      */}
      <ValuePropositions title={title} valuePropositions={valuePropositions} />
      <Form
        startOnboarding={startOnboarding}
        setDeveloperEmail={setDeveloperEmail}
        label={label}
        buttonText={buttonText}
        formTitle={formTitle}
        placeholder={placeholder}
        formState={formState}
        illustration={illustration}
        info={info}
      />
    </SignFormStyle>
  )
}

export default DevelopersSignUpPage
